import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth, guest, authTfa, guestTfa } from '@/router/middlewares';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/pages/Home'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/Login'),
            props: true,
            meta: {
                middleware: [guest],
                title: 'router.titles.login',
            },
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('@/pages/ForgotPassword'),
            meta: {
                middleware: [guest],
                title: 'router.titles.forgot_password',
            },
        },
        {
            path: '/password-reset',
            name: 'password-reset',
            component: () => import('@/pages/PasswordReset'),
            beforeEnter: (to, from, next) => {
                return JSON.stringify(['email', 'token'].sort()) !== JSON.stringify(Object.keys(to.query).sort())
                    ? next({ name: 'page-not-found' })
                    : next();
            },
            meta: {
                middleware: [guest],
                title: 'router.titles.password_reset',
            },
        },
        {
            path: '/application',
            name: 'application',
            component: () => import('@/pages/Application'),
            meta: {
                title: 'router.titles.application',
            },
        },
        {
            path: '/pay',
            name: 'pay',
            component: () => import('@/pages/Pay'),
            beforeEnter: (to, from, next) => (!to.query.hash ? next({ name: 'page-not-found' }) : next()),
            meta: {
                title: 'router.titles.pay',
            },
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import('@/pages/project/Projects'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.projects',
            },
        },
        {
            path: '/projects/:id/statistics',
            name: 'projects.statistics',
            component: () => import('@/pages/project/Statistics'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.statistics',
            },
        },
        {
            path: '/account',
            name: 'account',
            component: () => import('@/pages/Account'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.account',
            },
        },
        {
            path: '/tfa',
            name: 'tfa',
            component: () => import('@/pages/Tfa'),
            meta: {
                middleware: [auth, guestTfa],
                title: 'router.titles.tfa',
            },
        },
        {
            path: '/docs',
            name: 'docs',
            component: () => import('@/pages/Docs'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.docs',
            },
        },
        {
            path: '/faq',
            name: 'faq',
            component: () => import('@/pages/Faq'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.faq',
            },
        },
        {
            path: '*',
            name: 'page-not-found',
            component: () => import('@/pages/PageNotFound'),
        },
    ],
});

const nextFactory = (context, middleware, index) =>
    middleware[index]
        ? parameters =>
              !parameters ? middleware[index]({ ...context, next: nextFactory(context, middleware, index + 1) }) : context.next(parameters)
        : context.next;

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

        const context = { from, next, router, to };

        return middleware[0]({ ...context, next: nextFactory(context, middleware, 1) });
    }

    return next();
});

export default router;
