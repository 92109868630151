import i18n from '@/i18n';
import { configure, extend, setInteractionMode } from 'vee-validate';
import { confirmed, digits, email, max_value, min, min_value, required } from 'vee-validate/dist/rules';

configure({
    defaultMessage: (field, values) => {
        values._field_ = i18n.t(`validation.names.${field}`);
        return i18n.t(`validation.messages.${values._rule_}`, values);
    },
});

extend('confirmed', confirmed);
extend('digits', digits);
extend('email', email);
extend('max_value', max_value);
extend('min', min);
extend('min_value', min_value);
extend('required', required);

extend('different', {
    validate: (value, args) => String(value) !== String(args.target),
    params: [{ name: 'target', isTarget: true }],
});

extend('full_name', {
    validate: value => /^[a-zа-я\s]+$/i.test(value),
});

extend('url', {
    validate: value => /^(https?:\/\/(?:www\.)?)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&\/=]*$/.test(value),
});

extend('recovery_code', {
    validate: value => /^[a-zA-Z0-9]{10}-[a-zA-Z0-9]{10}$/.test(value),
});

setInteractionMode('lazy');
